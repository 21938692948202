import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Table, Modal, Button } from 'react-bootstrap';
import { exportToExcel } from './utils'; // Import your exportToExcel utility function

function Statistics() {
    const [votersList, setVotersList] = useState([]);
    const [shayachList, setShayachList] = useState([]);
    const [selectedIncharge, setSelectedIncharge] = useState(null);
    const [showVotersModal, setShowVotersModal] = useState(false);

    useEffect(() => {
        // Fetch voters list
        const fetchData = async () => {
            axios.get('https://tajamoa.com/backend/voters.php')
                .then(response => {
                    setVotersList(response.data);
                })
                .catch(error => {
                    console.error('Error fetching voters list:', error);
                });

            // Fetch shayach list
            axios.get('https://tajamoa.com/backend/shayach.php')
                .then(response => {
                    setShayachList(response.data);
                })
                .catch(error => {
                    console.error('Error fetching Shayach list:', error);
                });

        };

        fetchData(); // Initial fetch
        const interval = setInterval(() => {
            fetchData(); // Fetch data every few seconds
        }, 5000); // Interval in milliseconds (e.g., 5000 ms = 5 seconds)

        return () => clearInterval(interval);
    }, []);

    const totalVoters = votersList.length;
    const votedVoters = votersList.filter(voter => voter.voted === "1").length;
    const ourVoters = shayachList.length;
    const ourVotedVoters = shayachList.filter(voter =>
        votersList.some(v => v.ID === voter.ID && v.serialnumber === voter.serialnumber && v.voted === "1")
    ).length;

    const votersPerBoxID = votersList.reduce((acc, voter) => {
        acc[voter.boxID] = (acc[voter.boxID] || 0) + 1;
        return acc;
    }, {});

    const votersPerIncharge = shayachList.reduce((acc, shayach) => {
        const correspondingVoters = votersList.filter(voter =>
            voter.ID === shayach.ID && voter.voted === "1"
        );
        if (correspondingVoters.length > 0) {
            acc[shayach.incharge] = correspondingVoters.length;
        }
        return acc;
    }, {});

    const handleInchargeClick = (incharge) => {
        setSelectedIncharge(incharge);
        setShowVotersModal(true);
    };

    return (
        <div className="container mt-5">
            <h2 className="text-center mb-4">الإحصائيات</h2>
            <div className="row justify-content-center">
                <Col md={12}>
                    <Card bg="light" text="dark">
                        <Card.Body>
                            <Card.Title>الناخبين</Card.Title>
                            <p className="card-text">الكلي: <p className=' text-primary lead'>{totalVoters}</p></p>
                            <p className="card-text">الذين قاموا بالتصويت: <p className=' text-success lead'>{votedVoters}</p></p>
                            <p className="card-text">المدرجين لدينا: <p className=' text-primary lead'>{ourVoters}</p></p>
                            <p className="card-text">الذين قاموا بالتصويت والمدرجين لدينا: <p className=' text-warning lead'><strong>{ourVotedVoters}</strong></p></p>
                        </Card.Body>
                    </Card>
                </Col>
            </div>
            <div className="row justify-content-center mt-4">
                <Col md={6}>
                    <Card bg="light" text="dark">
                        <Card.Body>
                            <Card.Title>الناخبين لكل صندوق</Card.Title>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>رقم الصندوق</th>
                                        <th>عدد الناخبين</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(votersPerBoxID).map(boxID => (
                                        <tr key={boxID}>
                                            <td>{boxID}</td>
                                            <td>{votersPerBoxID[boxID]}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card bg="light" text="dark">
                        <Card.Body>
                            <Card.Title>الناخبين الذين قاموا بالتصويت لكل مسؤول</Card.Title>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>اسم المسؤول</th>
                                        <th>عدد الناخبين</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(votersPerIncharge).map(incharge => (
                                        <tr
                                            key={incharge}
                                            style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}
                                            onClick={() => handleInchargeClick(incharge)}
                                        >
                                            <td>{incharge}</td>
                                            <td>{votersPerIncharge[incharge]}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </div>
            <Modal show={showVotersModal}  onHide={() => setShowVotersModal(false)} dialogClassName="modal-90w" >
                <Modal.Header>
                    <Modal.Title>قائمة الناخبين للمسؤول: {selectedIncharge}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='h6'>ناخبين قامو بالتصويت</div>
                    <Button className='mb-2' variant='success' onClick={() => exportToExcel(shayachList.filter(shayach => shayach.incharge === selectedIncharge && votersList.find(voter => voter.ID === shayach.ID && voter.voted === "1")), 'votedVoters')}>Export to Excel</Button>
                    <Table striped bordered hover >
                        <thead>
                            <tr>
                                <th>رقم الهوية</th>
                                <th>الاسم</th>
                                <th>الرقم التسلسلي</th>
                                <th>الصندوق</th>
                                <th>المسؤول</th>
                                <th>المسؤول الثانوي</th>
                            </tr>
                        </thead>
                        <tbody>
                            {shayachList
                                .filter(shayach => shayach.incharge === selectedIncharge)
                                .map(shayach => {
                                    const voter = votersList.find(voter => voter.ID === shayach.ID && voter.voted === "1");
                                    if (voter) {
                                        return (
                                            <tr key={shayach.ID} style={{ backgroundColor: 'lightgreen' }}>
                                                <td>{shayach.ID}</td>
                                                <td>{voter.firstname} {voter.middlename} {voter.lastname}</td>
                                                <td>{shayach.serialnumber}</td>
                                                <td>{shayach.boxID}</td>
                                                <td>{shayach.incharge}</td>
                                                <td>{shayach.subincharge}</td>
                                            </tr>
                                        );
                                    } 
                                })}
                        </tbody>
                    </Table>
                    <br/>
                    <div className='h6'>ناخبين لم يقوموا بالتصويت</div>
                    <Button className='mb-2' variant='success' onClick={() => exportToExcel(shayachList.filter(shayach => shayach.incharge === selectedIncharge && !votersList.find(voter => voter.ID === shayach.ID && voter.voted === "1")), 'notVotedVoters')}>Export to Excel</Button>
                    <Table striped bordered hover variant="dark">
                        <thead>
                            <tr>
                                <th>رقم الهوية</th>
                                <th>الاسم</th>
                                <th>الرقم التسلسلي</th>
                                <th>الصندوق</th>
                                <th>المسؤول</th>
                                <th>المسؤول الثانوي</th>
                            </tr>
                        </thead>
                        <tbody>
                            {shayachList
                                .filter(shayach => shayach.incharge === selectedIncharge)
                                .map(shayach => {
                                    const checkvoter = votersList.find(voter => voter.ID === shayach.ID && voter.voted === "1");
                                    if (!checkvoter) 
                                    {
                                        const voter = votersList.find(voter => voter.ID === shayach.ID && voter.voted === "0");
                                        if (voter)
                                        {
                                            return (
                                                <tr key={shayach.ID} style={{ backgroundColor: 'red' }}>
                                                    <td>{shayach.ID}</td>
                                                    <td>{voter.firstname} {voter.middlename} {voter.lastname}</td>
                                                    <td>{shayach.serialnumber}</td>
                                                    <td>{shayach.boxID}</td>
                                                    <td>{shayach.incharge}</td>
                                                    <td>{shayach.subincharge}</td>
                                                </tr>
                                            );
                                        } 
                                    }
                                    
                                })}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowVotersModal(false)}>
                        إغلاق
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Statistics;
