import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Alert } from 'react-bootstrap';

function VoterVoted() {
    const [boxID, setBoxID] = useState('');
    const [serialNumber, setSerialNumber] = useState('');
    const [boxes, setBoxes] = useState([]);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    useEffect(() => {
        // Fetch available box IDs
        axios.get('https://tajamoa.com/backend/voting_boxes.php')
            .then(response => {
                setBoxes(response.data.map(box => box.ID));
            })
            .catch(error => {
                console.error('Error fetching box IDs:', error);
            });
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
    
        if (!boxID || !serialNumber) {
            alert('Please select a box ID and enter a serial number');
            return;
        }
    
        // Log the JSON data being sent
        const jsonData = JSON.stringify({ serialnumber: serialNumber, boxID: boxID });
        console.log('JSON data being sent:', jsonData);
    
        // Submit the form data to the backend
        axios.post('https://tajamoa.com/backend/voters.php', jsonData, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            console.log(response.data);
            setShowSuccessMessage(true);
        })
        .catch(error => {
            console.error('Error marking voter as voted:', error);
        });
    
        // Reset the form
        // setBoxID('');
        setSerialNumber('');
    };
    

    return (
        <div className="container mt-4">
            <h2 className="text-right mb-4">تسجيل صوت الناخب</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="boxID" className="form-label">اختر رقم الصندوق:</label>
                    <select
                        id="boxID"
                        className="form-select"
                        value={boxID}
                        onChange={(e) => setBoxID(e.target.value)}
                    >
                        <option value="">-- اختر رقم الصندوق --</option>
                        {boxes.map((box, index) => (
                            <option key={index} value={box}>{box}</option>
                        ))}
                    </select>
                </div>
                <div className="mb-3">
                    <label htmlFor="serialNumber" className="form-label">أدخل الرقم التسلسلي:</label>
                    <input
                        type="text"
                        className="form-control"
                        id="serialNumber"
                        value={serialNumber}
                        onChange={(e) => setSerialNumber(e.target.value)}
                    />
                </div>
                <button type="submit" className="btn" style={{backgroundColor: 'orange'}}>تسجيل الناخب كمقترع</button>
            </form>
            {showSuccessMessage && (
                <Alert variant="success" className="mt-3">
                    تم تسجيل صوت الناخب بنجاح.
                </Alert>
            )}
        </div>
    );
}

export default VoterVoted;
