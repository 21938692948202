import React, { useState } from 'react';
import './App.css';
import VotingBoxes from './components/VotingBoxes';
import VoterVoted from './components/VoterVoted';
import Statistics from './components/Statistics';
import Header from './components/Header';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faChartBar } from '@fortawesome/free-solid-svg-icons';

function App() {
  const [showVotingBoxes, setShowVotingBoxes] = useState(true);
  const [showStatistics, setShowStatistics] = useState(true);

  return (
    <BrowserRouter>
      <div className="App bg-light">
        <Header />
        <Routes>
          <Route path="/mark-voter" element={<VoterVoted />} />
          <Route path="/" element={
            <>
              <div className="fixed-buttons">
                <Button variant="primary" onClick={() => setShowVotingBoxes(!showVotingBoxes)}>
                  <FontAwesomeIcon icon={faBox} /> {showVotingBoxes ? '' : ''} 
                </Button>
                <Button variant="secondary" onClick={() => setShowStatistics(!showStatistics)}>
                  <FontAwesomeIcon icon={faChartBar} /> {showStatistics ? '' : ''} 
                </Button>
              </div>
              {showVotingBoxes && <VotingBoxes />}
              {showStatistics && <Statistics />}
            </>
          } />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
