import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function VoterBoxesList() {
    const [voterBoxes, setVoterBoxes] = useState([]);
    const [votersList, setVotersList] = useState([]);
    const [shayachList, setShayachList] = useState([]);
    const [numberOfVotersPerBox, setNumberOfVotersPerBox] = useState({});
    const [numberOfShayachVotersPerBox, setNumberOfShayachVotersPerBox] = useState({});
    const [numberOfShayachVotersVotedPerBox, setNumberOfShayachVotersVotedPerBox] = useState({});
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedNumber, setSelectedNumber] = useState('');
    const [votedVotersModal, setVotedVotersModal] = useState(false);
    const [shayachVotersModal, setShayachVotersModal] = useState(false);
    const [selectedBoxId, setSelectedBoxId] = useState(null);
    const [votedVoters, setVotedVoters] = useState([]);
    const [shayachVoters, setShayachVoters] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filterByIncharge, setFilterByIncharge] = useState('');
    const [selectedBoxIdForVoted, setSelectedBoxIdForVoted] = useState(null);
    const [selectedBoxIdForShayach, setSelectedBoxIdForShayach] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
        // Fetch voter boxes
        axios.get('https://tajamoa.com/backend/voting_boxes.php')
            .then(response => {
                setVoterBoxes(response.data);
            })
            .catch(error => {
                console.error('Error fetching voting boxes:', error);
            });

        // Fetch voters list
        axios.get('https://tajamoa.com/backend/voters.php')
            .then(response => {
                setVotersList(response.data);
            })
            .catch(error => {
                console.error('Error fetching voters list:', error);
            });

        // Fetch shayach list
        axios.get('https://tajamoa.com/backend/shayach.php')
            .then(response => {
                setShayachList(response.data);
            })
            .catch(error => {
                console.error('Error fetching Shayach list:', error);
            });

        };

        fetchData(); // Initial fetch
            const interval = setInterval(() => {
                fetchData(); // Fetch data every few seconds
            }, 5000); // Interval in milliseconds (e.g., 5000 ms = 5 seconds)
    
            return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const calculateNumberOfVoters = () => {
            const result = {};
            votersList.forEach(voter => {
                if (result[voter.boxID]) {
                    result[voter.boxID]++;
                } else {
                    result[voter.boxID] = 1;
                }
            });
            return result;
        };

        setNumberOfVotersPerBox(calculateNumberOfVoters());
    }, [votersList]);

    useEffect(() => {
        const calculateNumberOfShayachVoters = () => {
            const result = {};
            shayachList.forEach(voter => {
                if (result[voter.boxID]) {
                    result[voter.boxID]++;
                } else {
                    result[voter.boxID] = 1;
                }
            });
            return result;
        };

        const calculateNumberOfShayachVotersVoted = () => {
            const result = {};
            shayachList.forEach(voter => {
                const votedVoter = votersList.find(v => v.ID === voter.ID && v.serialnumber === voter.serialnumber && v.boxID === voter.boxID);
                if (votedVoter && votedVoter.voted === "1") {
                    if (result[voter.boxID]) {
                        result[voter.boxID]++;
                    } else {
                        result[voter.boxID] = 1;
                    }
                }
            });
            return result;
        };

        setNumberOfShayachVotersPerBox(calculateNumberOfShayachVoters());
        setNumberOfShayachVotersVotedPerBox(calculateNumberOfShayachVotersVoted());
    }, [shayachList, votersList]);

    const countVotedVoters = (boxID) => {
        return votersList.filter(voter => voter.boxID === boxID && voter.voted === "1").length;
    };

    const openVotedVotersModal = (boxID) => {
        setSelectedBoxIdForVoted(boxID);
        setVotedVotersModal(true);
        // Fetch and set the list of voters who voted for the selected box
        setVotedVoters(votersList.filter(voter => voter.boxID === boxID && voter.voted === "1"));
    };

    const openShayachVotersModal = (boxID) => {
        setSelectedBoxId(boxID);
        setShayachVotersModal(true);
        // Fetch and set the list of our voters who voted for the selected box including information from shayach
        setShayachVoters(shayachList.filter(voter => {
            const votedVoter = votersList.find(v => v.ID === voter.ID && v.serialnumber === voter.serialnumber && v.boxID === boxID);
            return votedVoter && votedVoter.voted === "1";
        }));
    };
    

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleInchargeFilter = (e) => {
        setFilterByIncharge(e.target.value);
    };

    const voterBoxesByLocation = voterBoxes.reduce((acc, box) => {
        acc[box.Location] = acc[box.Location] || [];
        acc[box.Location].push(box);
        return acc;
    }, {});

    return (
        <div className="container">
            <h2 className="text-right mb-4 pt-4 headerz">قائمة صناديق الناخبين</h2>
            <div className="row mb-4">
                <div className="col-md-6">
                    <label htmlFor="locationFilter" className="form-label">مكان الصندوق</label>
                    <select
                        id="locationFilter"
                        className="form-select"
                        value={selectedLocation}
                        onChange={(e) => setSelectedLocation(e.target.value)}
                    >
                        <option value="">كل الاماكن</option>
                        {Object.keys(voterBoxesByLocation).map((location) => (
                            <option key={location} value={location}>{location}</option>
                        ))}
                    </select>
                </div>
                <div className="col-md-6">
                    <label htmlFor="numberFilter" className="form-label">رقم الصندوق</label>
                    <select
                        id="numberFilter"
                        className="form-select"
                        value={selectedNumber}
                        onChange={(e) => setSelectedNumber(e.target.value)}
                    >
                        <option value="">كل الارقام</option>
                        {voterBoxes.map((box) => (
                            <option key={box.ID} value={box.ID}>{box.ID}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
                {voterBoxes
                    .filter(box => {
                        return (!selectedLocation || box.Location === selectedLocation)
                            && (selectedNumber === '' || box.ID.toString() === selectedNumber)
                    })
                    .map((box, index) => (
                        <div key={box.ID} className={`col${index % 2 === 0 ? ' start-new-row' : ''}`}>
                            <div className="card text-bg-dark mb-3">
                                <div className="card-header">
                                    <div className="box-id-circle" onClick={() => openVotedVotersModal(box.ID)}>
                                        {box.ID}
                                    </div>
                                    {box.Location}
                                </div>
                                <div className="card-body">
                                    <div className="row mb-3">
                                        <div className="col-8">
                                            <strong>عدد المصوتين الكلي:</strong>
                                        </div>
                                        <div className="col-4 text-end">
                                            {numberOfVotersPerBox[box.ID] || 0}
                                        </div>
                                    </div>
                                    <div className="row mb-3 bg-secondary rounded-5" onClick={() => openVotedVotersModal(box.ID)}>
                                        <div className="col-8">
                                            <strong>قامو بالتصويت:</strong>
                                        </div>
                                        <div className="col-4 text-end">
                                            {countVotedVoters(box.ID)}
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-8">
                                            <strong>عدد المصوتين التابعين لمجموعتنا:</strong>
                                        </div>
                                        <div className="col-4 text-end">
                                        <strong>  {numberOfShayachVotersPerBox[box.ID]} </strong>
                                        </div>
                                    </div>
                                    <div className="row mb-3 rounded-5" style={{backgroundColor: 'orange'}} onClick={() => openShayachVotersModal(box.ID)}>
                                        <div className="col-8 text-dark">
                                            <strong>قامو بالتصويت من مجموعتنا:</strong>
                                        </div>
                                        <div className="col-4 text-end text-dark" >
                                           <strong> {numberOfShayachVotersVotedPerBox[box.ID] || 0} </strong>                                       
                                        </div>
                                    </div>
                                    {/* Display other box information */}
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
            {/* Modal to display voters who voted */}
            <Modal show={votedVotersModal} onHide={() => setVotedVotersModal(false)}>
                <Modal.Header>
                    <Modal.Title>قائمة الناخبين الذين قاموا بالتصويت</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul>
                        {votedVoters.map((voter) => (
                            <li key={voter.ID}> {voter.ID} | {voter.firstname} {voter.middlename} {voter.lastname} | סידורי: {voter.serialnumber} </li>
                        ))}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setVotedVotersModal(false)}>
                        إغلاق
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Modal to display our voters who voted */}
            <Modal show={shayachVotersModal} onHide={() => setShayachVotersModal(false)}>
                <Modal.Header>
                    <Modal.Title>قائمة الناخبين الذين قاموا بالتصويت منا</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                    <select
    className="form-select"
    value={filterByIncharge}
    onChange={handleInchargeFilter}
>
    <option value="">اختيار مسؤول</option>
    {shayachVoters
        .map(voter => voter.incharge)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map(incharge => (
            <option key={incharge} value={incharge}>{incharge}</option>
        ))}
</select>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>رقم الهوية</th>
                                    <th>الاسم الأول</th>
                                    <th>الاسم الأخير</th>
                                    <th>رقم التسلسل</th>
                                    <th>المسؤول</th>
                                    <th>المسؤول الفرعي</th>
                                </tr>
                            </thead>
                            <tbody>
                                {shayachVoters
                                    .filter(voter => !filterByIncharge || voter.incharge === filterByIncharge)
                                    .map((voter, index) => {
                                        const correspondingVoter = votersList.find(v => v.ID === voter.ID);
                                        return (
                                            <tr key={voter.ID}>
                                                <td>{index + 1}</td>
                                                <td>{voter.ID}</td>
                                                <td>{correspondingVoter ? correspondingVoter.firstname : 'Unknown'}</td>
                                                <td>{correspondingVoter ? correspondingVoter.lastname : 'Unknown'}</td>
                                                <td>{voter.serialnumber}</td>
                                                <td>{voter.incharge}</td>
                                                <td>{voter.subincharge}</td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShayachVotersModal(false)}>
                        إغلاق
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default VoterBoxesList;
